import React, { Component } from "react";
import { Link } from "react-router-dom";

class FounderDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      
      <div className="blog-area margin-top-65">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="blog-content">
                <h2>
                நிறுவனர்   - பற்றிய குறிப்புகள்
                </h2>
                <p>
                குதுபுல் அக்தாப், சாஹிபுல் வக்த், ஷ‌ம்ஸுல் வுஜூத், அஷ்ஷெய்கு ஜமாலிய்யா
								அஸ்ஸய்யித் கலீல் அவ்ன் மௌலானா அல்ஹஸனிய்யுல் ஹுஸைனிய்யுல் 
								ஹாஷிமிய் நாயகம் அவர்கள்
                  </p>
                  <p>
								<b> வாப்பா நாயகம் </b>
								என்று அனைவராலும் அன்போடு அழைக்கப்படும் ஷெய்க் கலீல் அவ்ன் மௌலானா அவர்கள் அத்தரிகத்துல் ஹக்கிகத்துல் காதிரியா தரிக்காவின் ஆன்மீக தலைவராவார்கள்.
							</p>
							<p>
								இவர்கள் 1937 ஆம் ஆண்டு டிசம்பர் 20 ஆம் திகதி (ஹிஜ்ரி 1356ஆம் வ‌ருட‌ம் ஷ‌வ்வால் மாத‌ம் பிறை 16) திங்க‌ட்கிழ‌மை காலை 8.30 மணிக்கு இலங்கையில் வெலிகமை எனும் ஊரில் பிறந்தார்கள். இவர்கள் கண்மணி நாயகம் ﷺ அன்னவர்களின் 34 ஆம் பரம்பரையிலும் கௌதுல் அஃலம் முஹிய்யதீன் அப்துல் காதிர் ஜீலானி ரலியல்லாஹு அன்ஹு அவர்களின் 21 ஆம் பரம்பரையிலும் பிறந்தவர்கள்.  இவர்களின் தந்தையார் அறிஞர் ஷெய்க் ஜமாலியா ஸைய்யித் யாசீன் மௌலானா அவர்களாவார்கள்.
							</p> <p>
								இளம் வயதிலேயே அவர்கள் திருக்குர்ஆனை முழுவதுமாக‌ ஓதி முடித்து விட்டார்கள். மற்ற எல்லா அரபுக் கலைகளையும் அவர்களின் தந்தையிடம் முறைப்படி கற்றார்கள்.
							</p> <p>
								கலீல் அவ்ன் மௌலானா அவர்கள் தம் தந்தையிடம் கற்ற பாடங்கள்: தப்ஸீர், ஹதீஸ், உஸூல் ஹதீஸ், பிக்ஹு, உஸூல் பிக்ஹு, அகாஇத், தஸவ்வுப், அதப் இன்ஷா, பலாகத், தாரீக், ஸர்பு, நஹ்வு, மன்திக், இல்முல் மஆனி, பதீஉ, பல்ஸபா, ஹிஸாப், அரூள் முதலானவை ஆகும்.
							</p>
							<img className="founder-img" src={publicUrl + "assets/img/books/img-01.jpg"} alt="" width="400"/>
                			<p>
								இவர்கள் பன்மொழிப் பாண்டித்தியம் பெற்றவர்கள். இலங்கையில் வெலிகமை என்னும் ஊரில் அறபா சிரேஷ்ட வித்தியாலயத்தில் S.S.C. வரை ஆங்கில மொழியில் கற்று தேர்ந்தார்கள்.
							</p> <p>
								பின்னர் தமிழின் மேல் அவர்களுக்கு இருந்த தனிப்பட்ட ஆர்வத்தால் மீண்டும் S.S.C. தேர்வை தமிழிலும் எழுதி தேறினார்கள். அவர்களின் சொந்த முயற்சியால் பண்டிதப் பரீட்சை எழுதி தேர்ச்சி பெற்றார்கள்.
							</p> <p>
								பாடசாலையில் பயிற்று மொழி ஆங்கிலமாக இருந்தும் தமிழின் மீது அவர்களுக்கு இருந்த ஆர்வமிகுதியால் தமிழ் இலக்கண இலக்கியங்கள் மட்டுமல்லாது சங்ககால ஏனைய நூல்களையும் தாங்களாகவே கற்றுக் கொள்வதில் பேராசை கொண்டு அதில் மகத்தான வெற்றியும் பெற்றார்கள்.
							</p> <p>
								1953 ஆம் ஆண்டிற்கு முன்பிருந்தே அவர்கள் தமிழில் கவிதைகள் எழுதத் தொடங்கி விட்டார்கள். இரண்டொரு தமிழ் பாடல்கள் இலங்கைத் தினகரன் பத்திரிகையிலும் வெளிவந்துள்ளது.
							</p> <p>
								S.S.C. தேர்வுக்குப் பின் இரண்டு ஆண்டுகள் அவர்கள் ஆங்கில ஆசிரியராகப் பணிபுரிந்துள்ளார்கள். பின்னர் காலி என்னும் ஊரில் அரசினர் ஆசிரியர் பயிற்சிக் கலாசாலையில் சேர்ந்து இரண்டு ஆண்டுகள் படித்து வெற்றி பெற்று ஆசிரியர் தகுதிச் சான்றிதழ் பெற்றார்கள்.
							</p> <p>
								ஆசிரியர் பயிற்சி 1962  இல் முடிய, 1963 ஆம் ஆண்டில் அவர்கள் ஊரிலேயே உள்ள அரசு அரபா மத்திய கல்லூரியில் ஆசிரியர் பணியில் அமர்ந்தார்கள். இங்கு 10 ஆண்டுகள் பணிபுரிந்தார்கள். கிண்டர்கார்டன் முதல் 12ஆம் வகுப்புகள் வரை பாடம் கற்பித்துள்ளார்கள்.
							</p> <p>
								அதன்பின் 1972 ஆம் ஆண்டு அவர்களுக்கு அதிபராகப் பதவி உயர்வு கிடைக்கப்பெற்று 'குருணாகல்' என்னும் ஊரிலுள்ள பண்டாகொஸ்வத்தைக்கு மாற்றப்பட்டார்கள். அங்கு ஒரு வருடம் சேவை செய்தார்கள். பின்னர் அங்கிருந்து வட்டாரக் கல்வி அதிகாரியாக (CEO) பதவி உயர்வு பெற்றார்கள்.
							</p> <p>
								1973 ஆம் ஆண்டிலிருந்து தொடர்ந்து 5 ஆண்டு காலம் சிலாபம், புத்தளம் வட்டாரங்களில் C.E.O. ஆக பணிபுரிந்தார்கள். பின்னர் 1978ஆம் ஆண்டு அநுராதபுரத்திற்கு மாற்றப்பட்டார்கள். அங்கு பணியில் இருந்த சமயம் அவர்களுக்கு E.O. Education Officerஆக பதவி உயர்வு கிடைத்தது.
							</p> <p>
								தொடர்ந்து 12 ஆண்டுகள் அங்கு கல்வி அதிகாரியாக பணிபுரிந்தும் அவர்களுக்கு மாற்றம் கிடைக்காத காரணத்தால் பணியிலிருந்து தம்மை விடுவித்துக் கொண்டார்கள். அன்று முதல் அல்லாஹ் அவர்களை ஆன்மீகப் பணிக்கு முற்றிலுமாகத் திருப்பி விட்டான் போல.
							</p> <p>
								ஷெய்கு நாயகம் அவர்கள் இந்தியாவிற்கு 1966 ஆம் ஆண்டு அவர்களின் தந்தை நாயகம் மறைந்த 40ஆவது நாள் அன்று முதல் விஜயம் செய்தார்கள். அதன்பின்னர் 1968ஆம் ஆண்டு முதல் வருடா வருடம் இந்திய விஜயத்தை மேற்கொண்டு சுமார் 3 மாதங்கள் தமிழகத்தில் சுற்றுப் பயணம் செய்து பலநூறு முரீத்களுக்கு ஆன்மீக அறிவு புகட்டி வருகிறார்கள்.
							</p> <p>
								இதேபோல் 1998ஆம் ஆண்டு முதல் துபாய் நாட்டிற்கு விஜயம் மேற்கொண்டு ஐக்கிய அரபு எமிரேட்ஸ் முழுவதும் சுற்றுப் பயணம் செய்து அங்குள்ள பலநூறு முரீத்களுக்கு ஆன்மீக அறிவு போதித்து வருகிறார்கள்.
							</p> <p>
								இன்னும் 2002ஆம் ஆண்டு முதல் மலேசியா, சிங்கப்பூர் ஆகிய நாடுகளுக்கு திருவிஜயம் செய்து அங்குள்ள பலநூறு முரீத்களுக்கு ஆன்மீக அறிவும் வழங்கி வருகிறார்கள்.
							</p> <p>
								இவர்கள் நிறைய மார்க்க ஆன்மீக நூல்களை தமிழ் பேசும் உலகிற்கு வழங்கி உள்ளார்கள்.
							</p>
              <p>
								அஸ்செய்யித் ஷெய்க் கலீல் அவ்ன் மௌலானா (ஹஃபிதஹுல்லாஹ்) அவர்கள் வெளியிட்ட நூல்கள்:

							</p>
							<ul class="ul-books">
								<li> <i class="fas fa-book"></i> குறிஞ்சிச் சுவை (தமிழ் இலக்கிய நூல்) </li>
								<li> <i class="fas fa-book"></i> காமூஸ் அரபு-தமிழ் அகராதி </li>
								<li> <i class="fas fa-book"></i> மகானந்தாலங்கார மாலை (சித்திரக்கவி) </li>
								<li> <i class="fas fa-book"></i> அருள்மொழிக் கோவை (தமிழ் ஆங்கிலம்) </li>
								<li> <i class="fas fa-book"></i> பரமார்த்தத் தெளிவு </li>
								<li> <i class="fas fa-book"></i> நாயகர் பன்னிரு பாடல் (கவிதை) </li>
								<li> <i class="fas fa-book"></i> ஈழ வள நாட்டில் பயிர் பெருக்க வாரீர்! </li>
								<li> <i class="fas fa-book"></i> தாகிபிரபம் </li>
								<li> <i class="fas fa-book"></i> மனிதா (அமுத மொழிகள் தொகுப்பு) </li>
								<li> <i class="fas fa-book"></i> பத்ரு சஹாபாக்கள் மௌலித் (தமிழாக்கம்) </li>
								<li> <i class="fas fa-book"></i> பர்சன்ஜி மௌலித் (தமிழாக்கம்) </li>
								<li> <i class="fas fa-book"></i> யாசீன் நாயகம் (ரலியல்லாஹு அன்ஹு) வரலாறு </li>
								<li> <i class="fas fa-book"></i> குத்புகள் திலகம் யாசீன் மௌலானா (ரலியல்லாஹு அன்ஹு) - கவிதை </li>
								<li> <i class="fas fa-book"></i> பேரின்ப பாதை (ஞான அறிமுக நூல்) </li>
								<li> <i class="fas fa-book"></i> துஹ்பத்துல் முர்ஸலா (அரபு மூலம் தமிழ் மொழிபெயர்ப்பு) </li>
								<li> <i class="fas fa-book"></i> ரிஸாலத்துல் கௌதிய்யா (அரபுமூலம் தமிழ் மொழிபெயர்ப்பு) </li>
								<li> <i class="fas fa-book"></i> கஸீதத்துல் அஹ்மதிய்யா (அரபு - தமிழ் வாரிதாத்) </li>
								<li> <i class="fas fa-book"></i> இறையருட்பா (கவிதை) </li>
								<li> <i class="fas fa-book"></i> அற்புத அகிலநாதர் (கவிதை) </li>
								<li> <i class="fas fa-book"></i> மருள் நீக்கிய மாநபி (ஸல்லல்லாஹு அலைஹிவஸல்லம்) </li>
								<li> <i class="fas fa-book"></i> ஒளியை மறைக்கத் துணியும் தூசி </li>
								<li> <i class="fas fa-book"></i> இறைவலி செய்யிது முஹம்மது மௌலானா (கவிதை) </li>
								<li> <i class="fas fa-book"></i> உண்மை விளக்கம் </li>
							</ul>
							<p>
								வெளி வரவிருக்கும் நூல்கள்: அல் குர்ஆன் விளக்கவுரை (இரண்டாம் ஜுஸ்வுக்கு), செய்யிதுனா உமர் (ரலியல்லாஹு அன்ஹு) புராணம், “bபானஸுஆத்” கவிதைக்கு மொழிபெயர்ப்பும் விளக்கமும், நன்னூலுக்கு விளக்கவுரை.
							</p> <p>
								முழுக்க முழுக்க ஞானத்தையே கருப்பொருளாகக் கொண்டு வெளிவரும் மாத இதழ் ஒன்றை மறைஞானப் பேழை என்ற பெயரில் வெளியிடச் செய்துள்ளார்கள். இவர்கள் ஏற்படுத்திய ஏகத்துவ மெய்ஞ்ஞான சபையானது இலங்கை, இந்தியா, சிங்கப்பூர், மலேசியா, துபாய்,  குவைத், கத்தார் போன்ற நாடுகளில் இயங்கி வருகிறது.
							</p> <p>
								இந்த சபையின் வழிநடத்தலில் இந்தியாவில் திருச்சியில் மதரசதுல் ஹுஸனைன் பீ ஜாமியா யாசீன் என்னும் அரபு கலாசாலை இயங்கி வருகிறது. இந்த கலாசாலை உலக மற்றும் இஸ்லாமிய கல்வியை இலவசமாக சிறுவர்களுக்கு வழங்கி வருகிறது. அதேபோல் "அவ்னியா உலக சமாதான அறக்கட்டளை" என்னும் அறக்கட்டளை மூலம் பல சமுதாய மேம்பாட்டு திட்டங்களை மேற்கொண்டு வருகிறது.
							</p> <p>
								ஜிஹாத் என்பது நப்ஸ் உடன் போராடி வெல்லுவதே என்று போதிக்கும் மௌலானா அவர்கள் உலகெங்கும் வாழும் தமது ஆயிரக்கணக்கான முரீதுகளுக்கு அல் குர்ஆன், அல் ஹதீஸின் அடிப்படையில் இஸ்லாமிய ஆன்மீக கல்வியை ஊட்டி அவர்களை பரிபக்குவப்படுதுகிறார்கள். இவர்களின் முரீதுகள் இலங்கை, இந்தியா குறிப்பாக தமிழ்நாடு, சிங்கப்பூர், மலேசியா மற்றும் பல்வேறு நாடுகளில் வசித்து வருகின்றனர்.
							</p>
                <div className="author-social-area margin-top-50">
                  <div className="post-taglist">
                    <ul>
                      <li>
                        <a href="#">artical</a>
                      </li>
                      <li>
                        <a href="#">magazine</a>
                      </li>
                      <li>
                        <a href="#">lifestyle</a>
                      </li>
                      <li>
                        <a href="#">responsive</a>
                      </li>
                    </ul>
                  </div>
                  <div className="author-social-list">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-thumbs-o-up" /> Like{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook-f" /> Share{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter" /> Tweet{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-pinterest" /> Tweet{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* reply-area start  */}

                {/* reply-area end  */}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FounderDetails;
